import React from 'react';
import "./Featuresvideos.css";

const Featuresvideos3 = () => {
  return (
    <div className="videobxlist">
      <video
        width="480"
        height="480"
        className="lazy"
        autoPlay
        loop
        muted
      >
        <source src="/video/video_vra.mp4" type="video/mp4" />
        <source src="/video/video_vra.webm" type="video/webm" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default Featuresvideos3;
