"use client";
import React from "react";
import "./HomeFeatures.css";
import Featuresvideos from "@/component/HomePage/Featuresvideos";
import Featuresvideos2 from "@/component/HomePage/Featuresvideos2";
import Featuresvideos3 from "@/component/HomePage/Featuresvideos3";
import Featuresvideos4 from "@/component/HomePage/Featuresvideos4";



const HomeFeatures = () => {
   return (
      <>
         <div className="pr_sectionouter">
            <section className="common_section product_homlist padd-in">
               <div className="container">
                  <div className="pr_list">
                     <div className="pr-image-container order-2">
                        <div className="videobxlist">
                           <Featuresvideos />
                        </div>
                     </div>
                     <div className="pr-content-container">
                        <h3 className="prlist_conthead">
                           Robotic Process Automation (RPA) Areas
                        </h3>
                        <p className="prlist_contdesc">
                           Streamlining healthcare operations with intelligent automation
                           for enhanced<br /> efficiency and accuracy.
                        </p>
                        <ul>
                           <li>Patient Registration and Admission</li>
                           <li>Billing and Claims Processing</li>
                           <li>Electronic Medical Records (EMR) Management</li>
                           <li>Inventory and Supply Chain Management</li>
                           <li>Appointment Scheduling</li>
                           <li>Lab Test Processing and Reporting</li>
                           <li>Human Resources (HR) and Payroll</li>
                           <li>Compliance and Reporting</li>
                        </ul>
                     </div>
                  </div>
               </div>
            </section>
            <section className="common_section product_homlist product_homlistbg">
               <div className="container">
                  <div className="pr_list">
                     <div className="pr-image-container">
                        <Featuresvideos2 />
                        </div>
                     <div className="pr-content-container">
                        <h3 className="prlist_conthead">VR Applications for Hospitals</h3>
                        <p className="prlist_contdesc">Revolutionizing patient care and medical training through immersive virtual
                           reality experiences.
                        </p>
                        <ul>
                           <li>Patient Education and Engagement</li>
                           <li>Pain Management</li>
                           <li>Physical Rehabilitation</li>
                           <li>Empathy Training for Non-Patients</li>
                           <li>Medication Education for Patients</li>
                           <li>Staff Training on Medical Equipment</li>
                        </ul>
                     </div>
                  </div>
               </div>


            </section>
            <section className="common_section product_homlist">
               <div className="container">
                  <div className="pr_list">
                     <div className="pr-image-container order-2">

                        <Featuresvideos3 />

                     </div>
                     <div className="pr-content-container">
                        <h3 className="prlist_conthead">VitalPatch</h3>
                        <p className="prlist_contdesc">Wearable health-monitoring device in the field of Tele-Health. Never before
                           has such a small, elegant device provided so much valuable information for physicians and nurses. This
                           state-of-the-art biosensor monitors eight physiological measurements continuously, in real-time
                        </p>
                        <ul>
                           <li>Built for comfort, easy to apply</li>
                           <li>Live analysis of vitals</li>
                           <li>Waterproof</li>
                           <li>Historically stored encrypted raw data</li>
                           <li>Real-time measurement from home</li>
                           <li>Monitor offline</li>
                           <li>Live ECG reporting and arrhythmia detection</li>
                           <li>Ability to know the issue before it happens</li>
                        </ul>
                     </div>
                  </div>
               </div>
            </section>
            <section className="common_section product_homlist product_homlistbg healthmonitorproductlist">
               <div className="container">
                  <div className="pr_list">
                     <div className="pr-image-container">

                        <Featuresvideos4 />

                     </div>
                     <div className="pr-content-container">
                        <h3 className="prlist_conthead">Health Monitor</h3>
                        <p className="prlist_contdesc">HM is a multifunctional health detector providing 6 vitals signs - blood
                           pressure, Heart rate, Blood oxygen, body temperature, blood glucose, and ECG features.
                        </p>
                        <ul className="single_list">
                           <li>Accurate and fast measurement</li>
                           <li>Possible to provide SDK</li>
                           <li>Portability; small size and lightweight</li>
                           <li>Compatibility with IOS and Android systems</li>
                        </ul>
                     </div>
                  </div>
               </div>
            </section>
         </div>
      </>
   );
};

export default HomeFeatures;
