"use client";

import React from "react";
import Image from "next/image";
import "./WhatWedo.css";
import whatwedo1 from "@/Helper/images/new/home-healthcare.svg";
import whatwedo2 from "@/Helper/images/new/monitoring-patients.svg";
import whatwedo3 from "@/Helper/images/new/ai-based-automated-reports.svg";
import whatwedo4 from "@/Helper/images/new/drive-decision-making.svg";
import whatwedo5 from "@/Helper/images/new/real-time-monitoring-of-clinical.svg";


const WhatWedo = () => {
  return (
    <section className="common_section cards_wedo">
    <div className="container">
       <div className="common_headingcontent">
          <h3 className="com_hd">WHAT WE DO</h3>
          <p className="com_desc textcenter">We empower individuals and organizations by providing innovative solutions
             and exceptional services to <br/>achieve their goals
          </p>
       </div>
       <div className="cards_outer">
          <div className="wedo_card">
             <div className="wedo_cardtop">
               <Image
                src={whatwedo1}
                alt="Home healthcare"
                width={69}
                height={39}
                loading="lazy"
              />

             </div>
             <p>Simplify and optimize home healthcare for humanity with intelligent automation.</p>
          </div>
          <div className="wedo_card">
             <div className="wedo_cardtop">
                
                <Image
                src={whatwedo2}
                alt="Monitoring of patients"
                width={50}
                height={50}
                
                loading="lazy"
              />
             </div>
             <p>Continuous monitoring of patients' vital signs in real-time, every second, every day, while they're
                comfortable at home without having to visit the hospital.
             </p>
          </div>
          <div className="wedo_card">
             <div className="wedo_cardtop">
               
                <Image
                src={whatwedo3}
                alt="AI-based automated reports"
                width={54}
                height={56}
                
                loading="lazy"
              />
             </div>
             <p>Provide AI-based automated reports that contain insights and information to support healthcare
                professionals' decisions and improve the productivity and efficiency of day-to-day healthcare life.
             </p>
          </div>
          <div className="wedo_card">
             <div className="wedo_cardtop">
                
                <Image
                src={whatwedo4}
                alt="Drive decision making"
                width={55}
                height={51}
                
                loading="lazy"
              />
             </div>
             <p>Automate and digitalize healthcare which enhances customer experience and satisfaction, improved data
                to drive decision-making.
             </p>
          </div>
          <div className="wedo_card">
             <div className="wedo_cardtop">
                
                <Image
                src={whatwedo5}
                alt="Real-time monitoring of clinical physiological data"
                width={55}
                height={54}
                
                loading="lazy"
              />
             </div>
             <p>Provide wearable devices that allow for real-time monitoring of clinical physiological data, enabling
                the ability to know any issues before they happen and react sooner.
             </p>
          </div>
       </div>
    </div>
 </section>
  );
};

export default WhatWedo;
