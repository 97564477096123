"use client";

import React from "react";
import Link from "next/link";
import Image from "next/image";
import "./OurProducts.css";
import RobotIcon from "@/Helper/images/new/robot-icon.svg";
import VrIcon from "@/Helper/images/new/vr-icon.svg";
import VitalPatchIcon from "@/Helper/images/new/vitalpatch-icon.svg";
import HealthMonitorIcon from "@/Helper/images/new/health-monitor-icon.svg";



const OurProducts = () => {
  return (
    <section className="common_section our_prosection">
      <div className="container">
        <div className="common_headingcontent">
          <h3 className="com_hd">OUR PRODUCTS</h3>
          <p className="com_desc textcenter">
            Our healthcare products leverage advanced technology to improve patient
            outcomes, streamline operations, and
            <br />
            enhance the overall quality of care delivery.
          </p>
        </div>
        <div className="productsinner">
          <div className="product-card">
            <span className="pr_icoouter pr_icoouter1">
              <Image src={RobotIcon} alt="Robotic Process Automation" width={46} height={46} loading="lazy" />
            </span>
            <p className="pr_listhd">Robotic Process Automation</p>
            <p className="pr_listcontent">
              RPA in healthcare optimizes administrative workflows, enhancing efficiency and
              accuracy through automated execution of repetitive tasks.
            </p>
            <Link href="#">Explore more <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.93833 8.45473C7.86403 8.52321 7.80444 8.60579 7.76311 8.69755C7.72178 8.7893 7.69955 8.88835 7.69776 8.98879C7.69597 9.08923 7.71465 9.18899 7.75268 9.28213C7.79072 9.37527 7.84733 9.45988 7.91913 9.53091C7.99094 9.60194 8.07648 9.65794 8.17064 9.69556C8.2648 9.73318 8.36566 9.75166 8.46719 9.74988C8.56873 9.74811 8.66887 9.72613 8.76163 9.68524C8.85439 9.64436 8.93787 9.58542 9.0071 9.51192L13.0402 5.52251C13.1106 5.4532 13.1664 5.37082 13.2045 5.28011C13.2426 5.1894 13.2622 5.09214 13.2622 4.99391C13.2622 4.89569 13.2426 4.79843 13.2045 4.70772C13.1664 4.61701 13.1106 4.53463 13.0402 4.46532L9.0071 0.475905C8.93787 0.402413 8.85439 0.343467 8.76163 0.302584C8.66887 0.261701 8.56873 0.239718 8.46719 0.237946C8.36566 0.236174 8.2648 0.254649 8.17064 0.29227C8.07648 0.329891 7.99094 0.385888 7.91913 0.456918C7.84733 0.527948 7.79072 0.612558 7.75268 0.705699C7.71465 0.79884 7.69597 0.898603 7.69776 0.999039C7.69955 1.09948 7.72178 1.19853 7.76311 1.29028C7.80444 1.38204 7.86403 1.46462 7.93833 1.5331L10.6808 4.2459L0.910635 4.2459C0.710077 4.2459 0.517731 4.32471 0.375915 4.46499C0.234099 4.60527 0.154427 4.79553 0.154427 4.99391C0.154427 5.1923 0.234099 5.38256 0.375915 5.52284C0.517731 5.66312 0.710077 5.74193 0.910635 5.74193L10.6808 5.74193L7.93833 8.45473Z" fill="currentColor"/>
</svg></Link>
          </div>
          <div className="product-card">
            <span className="pr_icoouter pr_icoouter2">
              <Image src={VrIcon} alt="VR Application for Hospitals" width={42} height={39} loading="lazy" />
            </span>
            <p className="pr_listhd">VR Application for Hospitals</p>
            <p className="pr_listcontent">
              A Virtual Reality (VR) application for hospitals enhances patient care by
              providing immersive experiences for therapy, training, and pain management.
            </p>
            <Link href="#">Explore more 
            <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.93833 8.45473C7.86403 8.52321 7.80444 8.60579 7.76311 8.69755C7.72178 8.7893 7.69955 8.88835 7.69776 8.98879C7.69597 9.08923 7.71465 9.18899 7.75268 9.28213C7.79072 9.37527 7.84733 9.45988 7.91913 9.53091C7.99094 9.60194 8.07648 9.65794 8.17064 9.69556C8.2648 9.73318 8.36566 9.75166 8.46719 9.74988C8.56873 9.74811 8.66887 9.72613 8.76163 9.68524C8.85439 9.64436 8.93787 9.58542 9.0071 9.51192L13.0402 5.52251C13.1106 5.4532 13.1664 5.37082 13.2045 5.28011C13.2426 5.1894 13.2622 5.09214 13.2622 4.99391C13.2622 4.89569 13.2426 4.79843 13.2045 4.70772C13.1664 4.61701 13.1106 4.53463 13.0402 4.46532L9.0071 0.475905C8.93787 0.402413 8.85439 0.343467 8.76163 0.302584C8.66887 0.261701 8.56873 0.239718 8.46719 0.237946C8.36566 0.236174 8.2648 0.254649 8.17064 0.29227C8.07648 0.329891 7.99094 0.385888 7.91913 0.456918C7.84733 0.527948 7.79072 0.612558 7.75268 0.705699C7.71465 0.79884 7.69597 0.898603 7.69776 0.999039C7.69955 1.09948 7.72178 1.19853 7.76311 1.29028C7.80444 1.38204 7.86403 1.46462 7.93833 1.5331L10.6808 4.2459L0.910635 4.2459C0.710077 4.2459 0.517731 4.32471 0.375915 4.46499C0.234099 4.60527 0.154427 4.79553 0.154427 4.99391C0.154427 5.1923 0.234099 5.38256 0.375915 5.52284C0.517731 5.66312 0.710077 5.74193 0.910635 5.74193L10.6808 5.74193L7.93833 8.45473Z" fill="currentColor"/>
</svg>
</Link>
          </div>
          <div className="product-card">
            <span className="pr_icoouter pr_icoouter3">
              <Image src={VitalPatchIcon} alt="VitalPatch" width={32} height={46} loading="lazy" />
            </span>
            <p className="pr_listhd">VitalPatch</p>
            <p className="pr_listcontent">
              Wearable health-monitoring device in the field of Tele-Health. Never before has
              such a small, elegant device provided so much valuable information for physicians and nurses.
            </p>
            <Link href="#">Explore more <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.93833 8.45473C7.86403 8.52321 7.80444 8.60579 7.76311 8.69755C7.72178 8.7893 7.69955 8.88835 7.69776 8.98879C7.69597 9.08923 7.71465 9.18899 7.75268 9.28213C7.79072 9.37527 7.84733 9.45988 7.91913 9.53091C7.99094 9.60194 8.07648 9.65794 8.17064 9.69556C8.2648 9.73318 8.36566 9.75166 8.46719 9.74988C8.56873 9.74811 8.66887 9.72613 8.76163 9.68524C8.85439 9.64436 8.93787 9.58542 9.0071 9.51192L13.0402 5.52251C13.1106 5.4532 13.1664 5.37082 13.2045 5.28011C13.2426 5.1894 13.2622 5.09214 13.2622 4.99391C13.2622 4.89569 13.2426 4.79843 13.2045 4.70772C13.1664 4.61701 13.1106 4.53463 13.0402 4.46532L9.0071 0.475905C8.93787 0.402413 8.85439 0.343467 8.76163 0.302584C8.66887 0.261701 8.56873 0.239718 8.46719 0.237946C8.36566 0.236174 8.2648 0.254649 8.17064 0.29227C8.07648 0.329891 7.99094 0.385888 7.91913 0.456918C7.84733 0.527948 7.79072 0.612558 7.75268 0.705699C7.71465 0.79884 7.69597 0.898603 7.69776 0.999039C7.69955 1.09948 7.72178 1.19853 7.76311 1.29028C7.80444 1.38204 7.86403 1.46462 7.93833 1.5331L10.6808 4.2459L0.910635 4.2459C0.710077 4.2459 0.517731 4.32471 0.375915 4.46499C0.234099 4.60527 0.154427 4.79553 0.154427 4.99391C0.154427 5.1923 0.234099 5.38256 0.375915 5.52284C0.517731 5.66312 0.710077 5.74193 0.910635 5.74193L10.6808 5.74193L7.93833 8.45473Z" fill="currentColor"/>
</svg></Link>
          </div>
          <div className="product-card">
            <span className="pr_icoouter pr_icoouter4">
              <Image src={HealthMonitorIcon} alt="Health Monitor" width={37} height={37} loading="lazy" />
            </span>
            <p className="pr_listhd">Health Monitor</p>
            <p className="pr_listcontent">
              HM is a multifunctional health detector providing 6 vital signs - blood
              pressure, heart rate, blood oxygen, body temperature, blood glucose, and ECG features.
            </p>
            <Link href="#">Explore more <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.93833 8.45473C7.86403 8.52321 7.80444 8.60579 7.76311 8.69755C7.72178 8.7893 7.69955 8.88835 7.69776 8.98879C7.69597 9.08923 7.71465 9.18899 7.75268 9.28213C7.79072 9.37527 7.84733 9.45988 7.91913 9.53091C7.99094 9.60194 8.07648 9.65794 8.17064 9.69556C8.2648 9.73318 8.36566 9.75166 8.46719 9.74988C8.56873 9.74811 8.66887 9.72613 8.76163 9.68524C8.85439 9.64436 8.93787 9.58542 9.0071 9.51192L13.0402 5.52251C13.1106 5.4532 13.1664 5.37082 13.2045 5.28011C13.2426 5.1894 13.2622 5.09214 13.2622 4.99391C13.2622 4.89569 13.2426 4.79843 13.2045 4.70772C13.1664 4.61701 13.1106 4.53463 13.0402 4.46532L9.0071 0.475905C8.93787 0.402413 8.85439 0.343467 8.76163 0.302584C8.66887 0.261701 8.56873 0.239718 8.46719 0.237946C8.36566 0.236174 8.2648 0.254649 8.17064 0.29227C8.07648 0.329891 7.99094 0.385888 7.91913 0.456918C7.84733 0.527948 7.79072 0.612558 7.75268 0.705699C7.71465 0.79884 7.69597 0.898603 7.69776 0.999039C7.69955 1.09948 7.72178 1.19853 7.76311 1.29028C7.80444 1.38204 7.86403 1.46462 7.93833 1.5331L10.6808 4.2459L0.910635 4.2459C0.710077 4.2459 0.517731 4.32471 0.375915 4.46499C0.234099 4.60527 0.154427 4.79553 0.154427 4.99391C0.154427 5.1923 0.234099 5.38256 0.375915 5.52284C0.517731 5.66312 0.710077 5.74193 0.910635 5.74193L10.6808 5.74193L7.93833 8.45473Z" fill="currentColor"/>
</svg></Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurProducts;
