import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/src/app/page.css");
;
import(/* webpackMode: "eager" */ "/app/src/component/Blog/RecentBlog.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/component/HomePage/Banner.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/component/HomePage/Clients.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/component/HomePage/HomeFeatures.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/component/HomePage/HomeFormsection.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/component/HomePage/OurProducts.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/component/HomePage/WhatWedo.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/component/HomePage/Whoweare.js");
