"use client";

import React from "react";
import Link from "next/link";
import Image from "next/image";
import "./Clients.css";
import ClientLogo1 from "@/Helper/images/new/influence-healthcare.svg";
import ClientLogo2 from "@/Helper/images/new/medibiosense.svg";
import ClientLogo3 from "@/Helper/images/new/gippsland.svg";
import ClientLogo4 from "@/Helper/images/new/wa-heart-clinic.svg";
import ClientLogo5 from "@/Helper/images/new/sapphire-coast.svg";

const Clients = () => {
  return (
    <section className="clients">
      <div className="container">
        <div className="clients-inner">
          <h2>HERE ARE SOME OF OUR CLIENTS:</h2>
          <div className="clients-logos">
            <Image src={ClientLogo1} width={89} height={54} alt="Influence Healthcare" />
            <Image src={ClientLogo2} width={140} height={21} alt="MediBioSense" />
            <Image src={ClientLogo3} width={180} height={50} alt="Gippsland Cardiac Tech" />
            <Image src={ClientLogo4} width={188} height={49} alt="WA Heart Clinic" />
            <Image src={ClientLogo5} width={172} height={51} alt="Sapphire Coast Cardiology" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Clients;
