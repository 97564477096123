"use client";

import React, { useEffect, useState } from "react";
import { apiService } from "@/services/api.services";
import Link from "next/link";
import Image from "next/image";
import "./Banner.css";
import BannerRightImg from "@/Helper/images/new/digital_healthcare_logo.png";
import BannerRightImg2 from "@/Helper/images/new/healthcare-kid.svg";
import BannerRightImg3 from "@/Helper/images/new/healthcare.svg";
import BannerRightImg4 from "@/Helper/images/new/blood-donation.svg";

const Banner = () => {
  const [bannerVideo, setBannerVideo] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const utilityDataResponse = await apiService.getAllUtilityData();
        if (
          utilityDataResponse.status === 200 &&
          utilityDataResponse.data?.data?.attributes?.banner_video?.data
            ?.attributes?.url
        ) {
          setBannerVideo(
            `${utilityDataResponse.data?.data?.attributes?.banner_video?.data?.attributes?.url}`
          );
        }
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  return (
    <div className="banner_bg banner_section homebannersection">
      <div className="container">
        <div className="banner_inner">
          <div className="banner_left banner_common">
            <h1 className="ban_hd">
              We’re committed to making healthcare more accessible for everyone
              in Saudi Arabia.
            </h1>
            <p className="ban_content">
              We know that accessing quality health care can be difficult, which
              is why we’ve created our innovative application that allows people
              to get healthcare from the comfort of their own homes.
            </p>
            <Link href="#" title="Learn more" className="lrn_btn">
              Learn more
              <span>
                <svg
                  width="13"
                  height="11"
                  viewBox="0 0 13 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.89388 8.82547C7.82326 8.89127 7.76662 8.97062 7.72734 9.05879C7.68805 9.14696 7.66693 9.24213 7.66523 9.33864C7.66352 9.43515 7.68128 9.53101 7.71743 9.6205C7.75358 9.71 7.80738 9.7913 7.87563 9.85955C7.94388 9.9278 8.02518 9.98161 8.11468 10.0178C8.20418 10.0539 8.30004 10.0717 8.39654 10.07C8.49305 10.0683 8.58823 10.0471 8.67639 10.0078C8.76456 9.96856 8.84391 9.91192 8.90971 9.84131L12.743 6.00797C12.8099 5.94137 12.863 5.86222 12.8992 5.77506C12.9354 5.68789 12.954 5.59444 12.954 5.50006C12.954 5.40567 12.9354 5.31222 12.8992 5.22506C12.863 5.1379 12.8099 5.05874 12.743 4.99214L8.90971 1.15881C8.84391 1.08819 8.76456 1.03155 8.67639 0.992266C8.58823 0.952983 8.49305 0.93186 8.39654 0.930157C8.30004 0.928454 8.20418 0.946207 8.11468 0.982356C8.02518 1.0185 7.94388 1.07231 7.87563 1.14056C7.80738 1.20881 7.75358 1.29011 7.71743 1.37961C7.68128 1.46911 7.66352 1.56497 7.66523 1.66147C7.66693 1.75798 7.68805 1.85316 7.72734 1.94132C7.76662 2.02949 7.82326 2.10884 7.89388 2.17464L10.5005 4.78131L1.21429 4.78131C1.02367 4.78131 0.840852 4.85703 0.706061 4.99182C0.571269 5.12661 0.495544 5.30943 0.495544 5.50006C0.495544 5.69068 0.571268 5.8735 0.706061 6.00829C0.840852 6.14308 1.02367 6.21881 1.21429 6.21881L10.5005 6.21881L7.89388 8.82547Z"
                    fill="CurrentColor"
                  />
                </svg>
              </span>
            </Link>
          </div>
          <div className="banner_right">
            <Image
              src={BannerRightImg}
              className="banner_fi1"
              width={675}
              height={440}
              alt="Digital Healthcare Logo"
            />
            <Image
              src={BannerRightImg2}
              className="banner_fi2"
              width={391}
              height={220}
              alt="Healthcare Kit"
            />

            <Image
              src={BannerRightImg3}
              className="banner_fi3"
              width={345}
              height={234}
              alt="Healthcare"
            />
            <Image
              src={BannerRightImg4}
              className="banner_fi4"
              width={247}
              height={118}
              alt="Blood donation"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
