"use client";

import React from "react";
import Image from "next/image";
import "./Whoweare.css";
import whoweare1 from "@/Helper/images/new/our-vision.svg";
import whoweareMission from "@/Helper/images/new/our-mission.svg";
import Whowe1 from "@/Helper/images/new/healthcare-vision.svg";
import Whowe2 from "@/Helper/images/new/health-checking.svg";
import Whowe3 from "@/Helper/images/new/health-checking-illustration.svg";
import Whowe4 from "@/Helper/images/new/health-mission.svg";


const Whoweare = () => {
  return (
    <section className="common_section who_aresection">
      <div className="container">
        <div className="common_headingcontent">
          <h3 className="com_hd">WHO WE ARE</h3>
          <p className="com_desc textcenter">
            At HDH, we’re committed to making healthcare more accessible for everyone in
            Saudi Arabia. We know that accessing quality
            <br />
            health care can be difficult, which is why we’ve created
            our innovative application that allows people to get healthcare from
            <br />
            the comfort of their own homes.
          </p>
        </div>
        <div className="who_innersection">
          <div className="flex-item-left">
            <div className="whybox">
              <div className="whyboxicon">
                <Image src={whoweare1} width={60} height={60} alt="Our vision" />
              </div>
              <div className="whyboxcontent">
                <p className="why_bxhead">Our vision</p>
                <p className="why_bxdesc">
                We strive to make healthcare more convenient, efficient, and affordable for our customers across the country. Our ultimate goal is to make sure everyone in Saudi Arabia has access to the high-quality healthcare they deserve. 
                </p>
              </div>
            </div>
            <div className="whybox">
              <div className="whyboxicon">

                <Image src={whoweareMission} width={62} height={62} alt="Our mission" />
              </div>
              <div className="whyboxcontent">
                <p className="why_bxhead">Our mission</p>
                <p className="why_bxdesc">
                To earn the trust and exceed expectations of our clients by providing the highest quality home care services that enables them to stay comfortable and safe in their own homes.
                </p>
              </div>
            </div>
          </div>
          <div className="flex-item-right">
            <div className="fl_animationlft"></div>
            <div className="fl_animationlft fl_animationrgt"></div>
            <div className="flexbx1">
              <Image src={Whowe1} width={334} height={234} alt="Healthcare Vision" />
              <Image src={Whowe2} width={334} height={234} alt="Health Checking" />
              </div>
            <div className="flexbx1 secondrowflexbox1">
              <Image src={Whowe3} width={334} height={234} alt="health checking illustration" />
              <Image src={Whowe4} width={334} height={234} alt="Health Mission" />
              </div>
          </div>
        </div>
      </div>
    </section>
  );
};


export default Whoweare;
