"use client";
import { apiService } from "@/services/api.services";
import Image from "next/image";
import React, { useEffect, useState } from "react";
import "./RecentBlog.css";
import Link from "next/link";


const RecentBlog = ({ FilterKeyword = "-" }) => {
  const [recentBlogList, setRecentBlogList] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await apiService.getRecentBlogWithkeySearch({
          keyword: FilterKeyword,
        });
        if (response.status === 200) {
          setRecentBlogList(response.data?.data);
        }
      } catch (error) {
        console.log(error);
      }
    }

    fetchData();
  }, []);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: '2-digit' };
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-GB', options).format(date);
  };

 

  return recentBlogList?.length ? (
    <>
      <div className="container">
        <div class="common_headingcontent">
          <h3 class="com_hd">THE LATEST FROM DIGITAL HEALTH HOUSE</h3>
          <p class="com_desc textcenter">
            Here’s a selection of our latest announcements and media coverage
          </p>
        </div>

        <div className="blog_inner">
          {recentBlogList?.map((blog, index) => (
            <div className="bloglistbox">
              <Link href={`/blog/${blog?.attributes?.url_index}`} key={index}>
                <div className="blogtop" key={index}>
                  {blog?.attributes?.image?.data?.attributes?.url && (
                    <Image
                      src={`${blog?.attributes?.image?.data?.attributes?.url}`}
                      alt={blog?.attributes?.meta_title ?? "blog_img"}
                      className="blog_card_img"
                      width={340}
                      height={340}
                    />
                  )}
                </div>
                <div className="blogbottom">
                <span>{formatDate(blog?.attributes?.createdAt)}</span>
                  <p class="bl_hd">{blog?.attributes?.title}</p>
                  <p className="bl_cont">{blog?.attributes?.description}</p>
                </div>
              </Link>
            </div>
          ))}
        </div>
        <div class="blog_btm">
          <Link href="/blog" title=" VIEW ALL BLOGS" class="viewblog_btn">
            VIEW ALL BLOGS
            <span>
              <svg
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.2373 4.51172L14.3145 5.41016L18.4775 9.375H0V10.625H18.4775L14.3145 14.5898L15.2373 15.4883L21 10L15.2373 4.51172Z"
                  fill="currentcolor"
                ></path>
              </svg>
            </span>
          </Link>
        </div>
      </div>
    </>
  ) : null;
};

export default RecentBlog;
